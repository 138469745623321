import React from 'react'
// import { WebControl } from './utils/jsWebControl-1.0.0.min.js'
import $ from 'jquery'
import './playback.css'
import JSEncrypt from 'jsencrypt'
import {
  Layout,
  Card,
  Button,
  DatePicker, Row, Col
} from "antd";
import moment from 'moment';
// import "./videoback.css";
import { Link } from 'react-router-dom';



const { Content } = Layout;

let oWebControl = null
let WebControl = null
//声明公用变量
var initCount = 0;
var pubKey = '';
export const injectScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.src = src
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.head.appendChild(script)
  })
}


let nowtime = new Date();
let year = nowtime.getFullYear();
let month = nowtime.getMonth() + 1 > 9 ? nowtime.getMonth() + 1 : '0' + (nowtime.getMonth() + 1);;
let date = nowtime.getDate() ? nowtime.getDate() : '0' + (nowtime.getDate());


class App extends React.Component {
  state = {
    collapsed: false,
    thirdpartylist: [],
    permissionlist: [],
    warningListDataSource: [],
    // number: "a8945fb3a658472ab0b5a8e454664727",
    number: localStorage.getItem('indexCode')
  };

  componentWillUnmount() {
    oWebControl.JS_Disconnect().then(function () { // oWebControl 为 WebControl 的对象 // 断开与插件服务的连接成功 
    }, function () { // 断开与插件服务的连接失败 
    });
  }


  componentDidMount() {
    if (this.props.onRef !== undefined) {
      this.props.onRef(this);
    }
    this.setState({
      begintime: moment().subtract(1, 'days'),
      endtime: moment().valueOf(),
    })
    let that = this
    injectScript('https://mainimg.terabits.cn/jsWebControl-1.0.0.min.js').then(res => {
      WebControl = window.WebControl
      //加载后要做的事情
      that.initPlugin()
    })
  }

  // 创建WebControl实例与启动插件
  initPlugin(val) {
    const con1 = document.getElementsByClassName('ant-col-24')
    const con2 = document.getElementsByClassName('ant-layout-sider-children')
    let width = 850
    let height = 500
    // console.log(this.props.videoStatus)
    if (con1[0]) {
      width = con1[0].offsetWidth
      height = con2[0].offsetHeight - 20
    }
    let that = this
    oWebControl = new WebControl({
      szPluginContainer: "playWnd",                       //指定容器id
      iServicePortStart: 15900,                           //指定起止端口号，建议使用该值
      iServicePortEnd: 15909,
      cbConnectSuccess: function () {
        // setCallbacks();
        //实例创建成功后需要启动服务
        oWebControl.JS_StartService("window", {
          dllPath: "./VideoPluginConnect.dll"
        }).then(function () {
          oWebControl.JS_CreateWnd("playWnd", width, height).then(function () {         //JS_CreateWnd创建视频播放窗口，宽高可设定
            console.log("JS_CreateWnd success");
            that.init(val);                                 //创建播放实例成功后初始化
          });
        }, function () {

        });
      },
      cbConnectError: function () {
        console.log("cbConnectError");
        oWebControl = null;
        $("#playWnd").html("插件未启动，正在尝试启动，请稍候...");
        WebControl.JS_WakeUp("VideoWebPlugin://");        //程序未启动时执行error函数，采用wakeup来启动程序
        initCount++;
        if (initCount < 3) {
          setTimeout(function () {
            this.initPlugin();
          }, 3000)
        } else {
          $("#playWnd").html("插件启动失败，请检查插件是否安装！");
        }
      },
      cbConnectClose: function () {
        console.log("cbConnectClose");
        oWebControl = null;
      }
    });

  }

  //初始化
  init(val) {
    console.log(val)
    const con1 = document.getElementsByClassName('ant-col-24')
    const con2 = document.getElementsByClassName('ant-layout-sider-children')
    let width = 850
    let height = 500
    // console.log(this.props.videoStatus)
    if (con1[0]) {
      width = con1[0].offsetWidth
      height = con2[0].offsetHeight - 20
    }
    this.getPubKey(() => {

      var appkey = '25109273';                           //综合安防管理平台提供的appkey，必填
      var secret = this.setEncrypt('6ALpEZP3G8JSutQf5CCr');   //综合安防管理平台提供的secret，必填
      var ip = '47.110.52.139';                           //综合安防管理平台IP地址，必填
      var port = 4443;
      var playMode = 0;                                  //初始播放模式：0-预览，1-回放
      var snapDir = "D:\\SnapDir";                       //抓图存储路径
      var videoDir = "D:\\VideoDir";                     //紧急录像或录像剪辑存储路径
      var layout = "1x1";                                //playMode指定模式的布局
      var enableHTTPS = 1;                               //是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
      var encryptedFields = 'secret';					   //加密字段，默认加密领域为secret
      var showToolbar = 1;                               //是否显示工具栏，0-不显示，非0-显示
      var showSmart = 1;                                 //是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
      var buttonIDs = "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769";  //自定义工具条按钮
      //var reconnectTimes = 2;                            // 重连次数，回放异常情况下有效
      //var reconnectTime = 4;                             // 每次重连的重连间隔 >= reconnectTime
      ////////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

      oWebControl.JS_RequestInterface({
        funcName: "init",
        argument: JSON.stringify({
          appkey: appkey,                           //综合安防管理平台提供的appkey，必填
          secret: secret,   //综合安防管理平台提供的secret，必填
          ip: ip,                           //综合安防管理平台IP地址，必填
          //API网关IP地址
          playMode: playMode,                        //播放模式（决定显示预览还是回放界面）
          port: port,                                //端口
          snapDir: snapDir,                          //抓图存储路径
          videoDir: videoDir,                        //紧急录像或录像剪辑存储路径
          layout: layout,                            //布局
          enableHTTPS: enableHTTPS,                  //是否启用HTTPS协议
          encryptedFields: encryptedFields,          //加密字段
          showToolbar: showToolbar,                  //是否显示工具栏
          showSmart: showSmart,                      //是否显示智能信息
          buttonIDs: buttonIDs                       //自定义工具条按钮
          //reconnectTimes：reconnectTimes,            //重连次数
          //reconnectDuration：reconnectTime           //重连间隔
        })
      }).then(function (oData) {

        oWebControl.JS_Resize(width, height);  // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
      });
    });
  }


  // 获取公钥
  getPubKey(callback) {
    oWebControl.JS_RequestInterface({
      funcName: "getRSAPubKey",
      argument: JSON.stringify({
        keyLength: 1024
      })
    }).then(function (oData) {
      console.log(oData);
      if (oData.responseMsg.data) {
        pubKey = oData.responseMsg.data;
        callback()
      }
    })
  }


  // RSA加密
  setEncrypt = (value) => {
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubKey);
    return encrypt.encrypt(value);
  }
  // 预览
  boardPreivew = (cameraIndexCode) => {
    var streamMode = 0;                                     //主子码流标识：0-主码流，1-子码流
    var transMode = 1;                                      //传输协议：0-UDP，1-TCP
    var gpuMode = 0;                                        //是否启用GPU硬解，0-不启用，1-启用
    var wndId = -1;                                         //播放窗口序号（在2x2以上布局下可指定播放窗口）
    oWebControl.JS_RequestInterface({
      funcName: "startPreview",
      argument: JSON.stringify({
        cameraIndexCode: localStorage.getItem('cameraindexcode'),                //监控点编号
        streamMode: streamMode,                         //主子码流标识
        transMode: transMode,                           //传输协议
        gpuMode: gpuMode,                               //是否开启GPU硬解
        wndId: wndId                                     //可指定播放窗口
      })
    })

  }
  // 回放
  startPlayBack = (cameraIndexCode) => {

    var startTimeStamp = this.state.begintime;
    var endTimeStamp = this.state.endtime;
    // console.log(moment(startTimeStamp).format(), endTimeStamp)
    var recordLocation = 1;                                     //录像存储位置：0-中心存储，1-设备存储
    var transMode = 1;                                          //传输协议：0-UDP，1-TCP
    var gpuMode = 0;                                            //是否启用GPU硬解，0-不启用，1-启用
    var wndId = -1;                                             //播放窗口序号（在2x2以上布局下可指定播放窗口）
    // console.log(this.state.begintime)
    // console.log(this.state.endtime)
    oWebControl.JS_RequestInterface({
      funcName: "startPlayback",
      argument: JSON.stringify({
        cameraIndexCode: cameraIndexCode,                   //监控点编号
        startTimeStamp: Math.floor(startTimeStamp / 1000).toString(),  //录像查询开始时间戳，单位：秒
        endTimeStamp: Math.floor(endTimeStamp / 1000).toString(),      //录像结束开始时间戳，单位：秒
        recordLocation: recordLocation,                     //录像存储类型：0-中心存储，1-设备存储
        transMode: transMode,                               //传输协议：0-UDP，1-TCP
        gpuMode: gpuMode,                                   //是否启用GPU硬解，0-不启用，1-启用
        wndId: wndId                                         //可指定播放窗口
      })
    })
  }


  endPlayback = () => {
    oWebControl.JS_RequestInterface({
      funcName: "stopAllPlayback"
    })
  }

  //打开全屏
  openfull = () => {
    oWebControl.JS_RequestInterface({
      funcName: "setFullScreen"
    })
  }

  download = () => {
    window.open("https://mainimg.terabits.cn/VideoWebPlugin.exe", '_self')
  }


  number = (e) => {
    this.setState({
      number: e.target.value
    })
  }

  //时间筛选
  begintime = (e, b) => {
    console.log(e, b)
    this.setState({
      begintime: moment(b)
    })
  }

  //时间筛选
  endtime = (e, b) => {
    console.log(e, b)
    this.setState({
      endtime: moment(b)
    })
  }
  // 销毁插件
  destroy = () => {
    oWebControl.JS_DestroyWnd().then(function () { // oWebControl 为 WebControl 的对象
      // 销毁插件窗口成功
    }, function () {
      // 销毁插件窗口失败
    })
  }

  back = () => {
    window.location.href = '/app/device';
  }

  render() {
    return (
      <Layout style={{ margin: "20px 0px", background: 'white' }} id="playback">
        <Card title={localStorage.getItem('cameralocation') + "实时画面"} headStyle={{ color: '#2a2a2a', fontSize: '18px' }}
          extra={
            <div>
              <Button type="primary" onClick={this.boardPreivew} >
                播放实时画面
              </Button>
              <Button type="primary" onClick={this.back} style={{ marginLeft: "15px" }} >
                返回
              </Button>
            </div>}
        >
        </Card>
        <Row>
          <Col span={24}>
            <div id="playWnd" className="playWnds" ></div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default App;
