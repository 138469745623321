import React from "react";
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import {
    Card,
    Layout,
    Button,
    Input,
    Table,
    Select,
    Modal, DatePicker, message, Cascader, Tabs, Switch, Pagination, Drawer, Tree
} from "antd";
import {
    getdeviceList, getNotHaveList, getunitList, addsensor,
    addcamera, deletedevice, changestaus, getRealVideo, getdevicelog, activeDevice, url, getIscAreaList, getIscCamerasByIndexCode
} from '../axios';


import "./device.css";
import moment from 'moment';

const datatype = {
    1: '心跳数据',
    2: '报警数据',
    3: '上电数据',
    4: '下线数据',
}


const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { DirectoryTree } = Tree;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

// const dateFormat = 'YYYY-MM-DD';


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoListDataSource: [],
            device_ip: null,
            typenone: "inline-block",
            pageNum: 1,
            pageNumSize: 10,
            pageNums: 1,
            pageNumSizes: 10,
            unitlist: [],
            deviceList: JSON.parse(localStorage.getItem('unitTree')),
            deviceLists: JSON.parse(localStorage.getItem('unitTree')),
            typedis: "none"
        };


        this.airColumns = [
            {
                title: "设备编号",
                dataIndex: "deviceId",
            },
            {
                title: "所属单位",
                dataIndex: "unit",
            },
            {
                title: "安装位置",
                dataIndex: "location",
            }, {
                title: "设备IMEI",
                dataIndex: "imei",
            }, {
                title: "信号强度",
                dataIndex: "rssi",
            },
            {
                title: "设备状态",
                dataIndex: "statusConnect",
                filters: [
                    { text: "在线", value: 1 },
                    { text: "离线", value: 0 },
                ],
                onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
                render: (text, record, index) => {
                    if (text === 1) {
                        return (
                            <div style={{ color: '#1eb333', cursor: 'pointer' }} onClick={() => this.onlinelist(text, record, index)}>
                                在线
                            </div>
                        )
                    }
                    if (text === 0) {
                        return (
                            <div style={{ color: '#f55238', cursor: 'pointer' }} onClick={() => this.onlinelist(text, record, index)}>
                                离线
                            </div>
                        )
                    }
                }
            },
            {
                title: "短信推送",
                dataIndex: "sendMsg",
                render: (text, record, index) => {
                    return (
                        <div >
                            <Switch
                                checked={text}
                                checkedChildren="开启" unCheckedChildren="关闭"
                                onChange={() => this.switchchange(text, record, index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: "添加时间",
                dataIndex: "gmtCreate",
                sorter: (a, b) => new Date(a.gmtCreate) > new Date(b.gmtCreate) ? 1 : -1,
            },
            {
                title: "最近连接时间",
                dataIndex: "lastConnect",
                sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
                render: (text, record, index) => {
                    if (text === null) {
                        return (
                            <div>
                                无
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {text}
                            </div>
                        )
                    }
                }
            },
            {
                title: "操作",
                dataIndex: "gmtCreate",
                render: (text, record, index) => {
                    return (
                        <div>
                            <span onClick={() => this.airedit(text, record, index)}>
                                <a><img src={require('../images/edit.png')} alt="" /></a>
                            </span>
                            <span style={{ marginLeft: '10px' }} onClick={() => this.devicedelete(text, record, index)}>
                                <a><img src={require('../images/delete.png')} alt="" /></a>
                            </span>
                        </div>
                    );
                }
            },

        ];


        if (localStorage.getItem('usertype') === "1") {
            this.nodeInfoTableColumns = [
                {
                    title: "设备编号",
                    dataIndex: "deviceId",
                },
                {
                    title: "所属单位",
                    dataIndex: "unit",
                },
                {
                    title: "安装位置",
                    dataIndex: "location",
                }, {
                    title: "设备IMEI",
                    dataIndex: "imei",
                }, {
                    title: "信号强度",
                    dataIndex: "rssi",
                },
                {
                    title: "设备状态",
                    dataIndex: "statusConnect",
                    filters: [
                        { text: "在线", value: 1 },
                        { text: "离线", value: 0 },
                    ],
                    onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div style={{ color: '#1eb333', cursor: 'pointer' }} onClick={() => this.onlinelist(text, record, index)}>
                                    在线
                                </div>
                            )
                        }
                        if (text === 0) {
                            return (
                                <div style={{ color: '#f55238', cursor: 'pointer' }} onClick={() => this.onlinelist(text, record, index)}>
                                    离线
                                </div>
                            )
                        }
                    }
                },
                {
                    title: "短信推送",
                    dataIndex: "sendMsg",
                    render: (text, record, index) => {
                        return (
                            <div >
                                <Switch
                                    checked={text}
                                    checkedChildren="开启" unCheckedChildren="关闭"
                                    onChange={() => this.switchchange(text, record, index)}
                                />
                            </div>
                        )
                    }
                },
                {
                    title: "添加时间",
                    dataIndex: "gmtCreate",
                    sorter: (a, b) => new Date(a.gmtCreate) > new Date(b.gmtCreate) ? 1 : -1,
                },
                {
                    title: "最近连接时间",
                    dataIndex: "lastConnect",
                    sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
                    render: (text, record, index) => {
                        if (text === null) {
                            return (
                                <div>
                                    无
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    {text}
                                </div>
                            )
                        }
                    }
                },
                {
                    title: "操作",
                    dataIndex: "gmtCreate",
                    render: (text, record, index) => {
                        return (
                            <div>
                                <span onClick={() => this.sensoredit(text, record, index)}>
                                    <a><img src={require('../images/edit.png')} alt="" /></a>
                                </span>
                                <span style={{ marginLeft: '10px' }} onClick={() => this.devicedelete(text, record, index)}>
                                    <a><img src={require('../images/delete.png')} alt="" /></a>
                                </span>
                            </div>
                        );
                    }
                },

            ];

        } else {
            this.nodeInfoTableColumns = [
                {
                    title: "设备编号",
                    dataIndex: "deviceId",
                },
                {
                    title: "所属单位",
                    dataIndex: "unit",
                },
                {
                    title: "安装位置",
                    dataIndex: "location",
                }, {
                    title: "设备IMEI",
                    dataIndex: "imei",
                }, {
                    title: "信号强度",
                    dataIndex: "rssi",
                },
                {
                    title: "设备状态",
                    dataIndex: "statusConnect",
                    filters: [
                        { text: "在线", value: 1 },
                        { text: "离线", value: 0 },
                    ],
                    onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div style={{ color: '#1eb333', cursor: 'pointer' }} onClick={() => this.onlinelist(text, record, index)}>
                                    在线
                                </div>
                            )
                        }
                        if (text === 0) {
                            return (
                                <div style={{ color: '#f55238', cursor: 'pointer' }} onClick={() => this.onlinelist(text, record, index)}>
                                    离线
                                </div>
                            )
                        }
                    }
                },
                {
                    title: "添加时间",
                    dataIndex: "gmtCreate",
                    sorter: (a, b) => new Date(a.gmtCreate) > new Date(b.gmtCreate) ? 1 : -1,
                },
                {
                    title: "最近连接时间",
                    dataIndex: "lastConnect",
                    sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
                    render: (text, record, index) => {
                        if (text === null) {
                            return (
                                <div>
                                    无
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    {text}
                                </div>
                            )
                        }
                    }
                },

            ];

        }


    }

    componentWillMount() {
        document.title = "设备管理";
        if (localStorage.getItem("usertype") === "1") {
            this.setState({
                typedis: 'inline'
            })
        } else {
            this.setState({
                typedis: 'none'
            })
        }
    }

    componentDidMount() {
        this.getcameraList()
        this.getsensorlist()
        this.getairlist()
        this.getunitList()



    }


    getsensorlist = () => {
        getdeviceList([
            localStorage.getItem('token'),
            [1, 3].toString(),
            31,
            this.state.cityid,
            this.state.areaid,
            this.state.siteId,
            this.state.searchnames,
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                console.log(res.data.data)
                var arr = []
                for (var i in res.data.data) {
                    arr.push(res.data.data[i].id)
                }
                this.setState({
                    senorids: arr.join(','),
                    sensorlist: res.data.data
                }, function () {
                    console.log(this.state.cameraids)
                    if (this.state.sensorlist.length > 10) {
                        this.setState({
                            page: true,
                        })
                    } else {
                        this.setState({
                            page: false,
                        })
                    }
                })
            }
        });
    }


    getcameraList = () => {
        getdeviceList([
            localStorage.getItem('token'),
            5,
            31,
            this.state.cityid,
            this.state.areaid,
            this.state.siteId,
            this.state.searchname,
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                var arr = []
                for (var i in res.data.data) {
                    arr.push(res.data.data[i].id)
                }
                this.setState({
                    cameraids: arr.join(','),
                    cameralist: res.data.data
                }, function () {
                    if (this.state.cameralist.length > 10) {
                        this.setState({
                            camerapage: true,
                        })
                    } else {
                        this.setState({
                            camerapage: false,
                        })
                    }
                })
            }
        });
    }

    getairlist = () => {
        getdeviceList([
            localStorage.getItem('token'),
            [1, 3].toString(),
            31,
            this.state.cityids,
            this.state.areaids,
            this.state.siteIds,
            this.state.searchnamess,
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                var arr = []
                for (var i in res.data.data) {
                    arr.push(res.data.data[i].id)
                }
                this.setState({
                    airids: arr.join(','),
                    airlist: res.data.data
                }, function () {
                    if (this.state.airids.length > 10) {
                        this.setState({
                            airpage: true,
                        })
                    } else {
                        this.setState({
                            airpage: false,
                        })
                    }
                })
            }
        });
    }

    //打开添加设备弹窗
    adddevice = () => {
        this.getunitList()
        this.setState({
            devicevisible: true,
        })
    }

    //打开空气道添加设备弹窗
    addair = () => {
        this.getunitList()
        this.setState({
            airvisible: true,
        })
    }



    //获取单位列表
    getunitList = () => {
        getunitList([
            localStorage.getItem('token'),
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                var arr = []
                for (var i in res.data.data) {
                    arr.push({
                        'id': res.data.data[i].id,
                        'name': res.data.data[i].unit,
                    })
                }
                this.setState({
                    unitlist: arr
                })
            }
        });
    }

    //检测状态
    switchchange = (text, record, index) => {
        changestaus([
            record.id,
            record.sendMsg === 1 ? 0 : 1
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                message.success("状态修改成功");
                this.getcameraList()
                this.getsensorlist()
                this.getairlist()
            } else {
                message.error(res.data.message)
            }
        });
    }


    //关闭弹窗
    handleCancel = () => {
        this.setState({
            devicevisible: false,
            devicedeletevisible: false,
            cameravisible: false,
            recordvisible: false,
            sensorid: undefined,
            cameraid: undefined,
            deviceIds: undefined,
            unitnames: undefined,
            airvisible: undefined,
            locations: undefined,
            deviceId: undefined,
            imei: undefined,
            unitname: undefined,
            location: undefined,
            camerarecord: undefined,
            activevisible: false
        })
    }

    //关闭弹窗
    handleCancels = () => {
        this.setState({
            videovisible: false,
            videourl: undefined,
        })
        // // this.getcameraList()
        // window.location.reload()
    }


    //打开实时画面
    oprealLive = (text, record, index) => {
        console.log(record)
        localStorage.setItem('cameraindexcode', record.cameraIndexCode)
        localStorage.setItem('cameralocation', record.location)
        // if (record.statusConnect === 0) {
        //     message.error('设备离线')
        // } else {
        //     getRealVideo([
        //         record.id,
        //     ]).then(res => {
        //         if (res.data && res.data.message === "success") {
        //             this.setState({
        //                 videovisible: true,
        //                 videourl: res.data.data
        //             })
        //         } else {
        //             message.error(res.data.message)
        //         }
        //     });

        // }

        window.location.href = '/app/playback';


        // if (!text) {

        // } else {
        //     this.setState({
        //         videovisible: true,
        //         videourl: text
        //     })
        // }
    }




    //设备类型选择
    devicetype = (value) => {
        this.setState({
            devicetype: value
        })
    }

    //烟感编号
    deviceId = (e) => {
        this.setState({
            deviceId: e.target.value
        })
    }


    //摄像头编号
    deviceIds = (value) => {
        this.setState({
            deviceIds: value
        })
    }



    //设备位置
    location = (e) => {
        this.setState({
            location: e.target.value
        })
    }

    //摄像头设备位置
    locations = (e) => {
        this.setState({
            locations: e.target.value
        })
    }

    //空气道设备位置
    locationss = (e) => {
        this.setState({
            locationss: e.target.value
        })
    }

    //所属单位
    unitname = (value) => {
        this.setState({
            unitname: value
        })
    }

    //摄像头所属单位
    unitnames = (value) => {
        this.setState({
            unitnames: value
        })
    }


    //空气道所属单位
    unitnamess = (value) => {
        this.setState({
            unitnamess: value
        })
    }

    //添加摄像头
    cameraOk = () => {
        addcamera([
            5,
            this.state.deviceIds,
            this.state.unitnames,
            this.state.locations,
            this.state.indexCode,
            this.state.cameraid,
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                if (!this.state.cameraid) {
                    message.success('添加成功')
                } else {
                    message.success('修改成功')
                }
                this.setState({
                    cameravisible: false,
                    cameraid: undefined
                })
                this.handleCancel()
                this.getcameraList()
            } else {
                message.error(res.data.message)
            }
        });
    }

    //添加烟感
    addOk = () => {
        addsensor([
            this.state.devicetype,
            this.state.deviceId,
            this.state.imei,
            this.state.unitname,
            this.state.location,
            this.state.sensorid,
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                if (!this.state.sensorid) {
                    message.success('添加成功')
                } else {
                    message.success('修改成功')
                }

                this.setState({
                    devicevisible: false,
                    sensorid: undefined,
                })
                this.handleCancel()
                this.getsensorlist()
            } else {
                message.error(res.data.message)
            }
        });
    }



    //打开删除设备弹窗
    devicedelete = (text, record, index) => {
        this.setState({
            devicedeletevisible: true,
            deviceid: record.id
        })
    }



    //删除设备
    deletedevice = () => {
        deletedevice([
            this.state.deviceid,
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                message.success('删除成功')
                this.setState({
                    devicedeletevisible: false,
                })
                this.getcameraList()
                this.getsensorlist()
                this.getairlist()
            } else {
                message.error(res.data.message)
            }
        });
    }

    //设备位置选择
    addresschange = (e) => {
        console.log(e)
        this.setState({
            addresslist: e,
            cityid: e[0] === undefined ? null : e[0],
            areaid: e[1] === undefined ? null : e[1],
            siteId: e[2] === undefined ? null : e[2]
        });
    }

    //设备位置选择
    addresschanges = (e) => {
        console.log(e)
        this.setState({
            addresslists: e,
            cityids: e[0] === undefined ? null : e[0],
            areaids: e[1] === undefined ? null : e[1],
            siteIds: e[2] === undefined ? null : e[2]
        });
    }


    //搜索框录入
    searchname = (e) => {
        this.setState({
            searchname: e.target.value
        })
    }

    //传感器搜索框录入
    searchnames = (e) => {
        this.setState({
            searchnames: e.target.value
        })
    }

    //空气道搜索框录入
    searchnamess = (e) => {
        this.setState({
            searchnamess: e.target.value
        })
    }


    //imei号输入
    imei = (e) => {
        this.setState({
            imei: e.target.value.replace(/[^0-9.]/g, '').length > 15 ? e.target.value.substring(0, 15) : e.target.value.replace(/[^0-9.]/g, '')
        })
    }

    //imei号输入
    imeis = (e) => {
        this.setState({
            imeis: e.target.value.replace(/[^0-9.]/g, '').length > 15 ? e.target.value.substring(0, 15) : e.target.value.replace(/[^0-9.]/g, '')
        })
    }



    //搜索
    onsearch = () => {
        this.getcameraList()
    }

    //传感器编号搜索
    onsearchs = () => {
        this.getsensorlist()
    }



    //打开摄像头弹窗
    addcamera = () => {
        this.getunitList()
        this.setState({
            cameravisible: true,
        })
    }

    //编辑传感器
    sensoredit = (text, record, index) => {
        this.setState({
            deviceId: record.deviceId,
            imei: record.imei,
            sensorid: record.id,
            unitname: record.unitId.toString(),
            location: record.location,
            devicevisible: true
        })
    }



    //编辑摄像头
    cameraedit = (text, record, index) => {
        console.log(record)
        this.setState({
            deviceIds: record.encodeDevIndexCode,
            cameraid: record.id,
            unitnames: record.unitId.toString(),
            locations: record.location,
            cameravisible: true
        })
    }

    //传感器上下线记录
    onlinelist = (text, record, index) => {
        this.setState({
            deviceId: record.deviceId
        }, function () {
            getdevicelog([
                this.state.pageNums,
                this.state.pageNumSizes,
                record.deviceId,
                1,
                [1, 3, 4].join(',')
            ]).then(res => {
                if (res.data && res.data.message === "success") {
                    this.setState({
                        recordlist: res.data.data.list,
                        recordvisible: true,
                        sensortotal: res.data.data.total
                    })
                }
            });
        });
    }

    //传感器分页变化
    sensorchange = (page, b) => {
        console.log(page, b)
        this.setState({
            pageNums: page,
            pageNumSizes: b,
        }, function () {
            getdevicelog([
                this.state.pageNums,
                this.state.pageNumSizes,
                this.state.deviceId,
                1,
                [1, 3, 4].join(',')
            ]).then(res => {
                if (res.data && res.data.message === "success") {
                    this.setState({
                        recordlist: res.data.data.list,
                        recordvisible: true,
                        sensortotal: res.data.data.total
                    })
                }
            });
        })
    }


    //摄像头上下线记录
    lookcamera = (text, record, index) => {
        console.log(record)
        this.setState({
            deviceId: record.deviceId
        }, function () {
            getdevicelog([
                this.state.pageNum,
                this.state.pageNumSize,
                this.state.deviceId,
                2,
                [3, 4].join(',')
            ]).then(res => {
                if (res.data && res.data.message === "success") {
                    this.setState({
                        recordlist: res.data.data.list,
                        camerarecord: true,
                        cameratotal: res.data.data.total
                    })
                }
            });
        })

    }


    //摄像头页数变化
    pagechange = (page, b) => {
        console.log(page, b)
        this.setState({
            pageNum: page,
            pageNumSize: b,
        }, function () {
            getdevicelog([
                this.state.pageNum,
                this.state.pageNumSize,
                this.state.deviceId,
                2,
                [3, 4].join(',')
            ]).then(res => {
                if (res.data && res.data.message === "success") {
                    this.setState({
                        recordlist: res.data.data.list,
                        camerarecord: true,
                        cameratotal: res.data.data.total
                    })
                }
            });
        })
    }

    //激活设备
    activeion = (text, record, index) => {
        this.setState({
            cameraid: record.id,
            activevisible: true
        })
    }

    //关闭摄像头弹窗
    submitcamera = () => {
        this.setState({
            iscvisible: false,
        })
    }


    //输入激活码
    activenum = (e) => {
        this.setState({
            activenum: e.target.value
        })
    }

    //确认激活设备
    activeok = () => {
        activeDevice([
            this.state.activenum,
            this.state.cameraid,
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                message.success('设备激活成功')
                this.getcameraList()
                this.setState({
                    activevisible: false,
                    cameraid: undefined,
                })
            } else {
                message.error(res.data.message)
            }
        });
    }


    //摄像头数据导出
    export = () => {
        if (this.state.cameraids === "") {
            message.error('请选择要导出的数据')
        } else {
            window.open(url + '/api/v1/device/export?access_token=' + localStorage.getItem('token') + "&deviceType=2&ids=" + this.state.cameraids, "_self")
        }
    }


    //传感器数据导出
    exports = () => {
        if (this.state.senorids === "") {
            message.error('请选择要导出的数据')
        } else {
            window.open(url + '/api/v1/device/export?access_token=' + localStorage.getItem('token') + "&deviceType=1&ids=" + this.state.senorids, "_self")
        }
    }


    //传感器数据导出
    exportss = () => {
        if (this.state.airids === "") {
            message.error('请选择要导出的数据')
        } else {
            window.open(url + '/api/v1/device/export?access_token=' + localStorage.getItem('token') + "&deviceType=3&ids=" + this.state.airids, "_self")
        }
    }


    //摄像头数据筛选
    cameralistchange = (a, b, c, d) => {
        console.log(d)
        var arr = []
        for (var i in d.currentDataSource) {
            arr.push(d.currentDataSource[i].id)
        }
        this.setState({
            cameraids: arr.join(',')
        })
    }

    //传感器数据筛选
    sensorlistchange = (a, b, c, d) => {
        var arr = []
        for (var i in d.currentDataSource) {
            arr.push(d.currentDataSource[i].id)
        }
        this.setState({
            senorids: arr.join(',')
        })
    }



    //摄像头列表
    iscquery = () => {
        getIscAreaList([

        ]).then(res => {
            if (res.data && res.data.message === "success") {
                const ass = (data) => {
                    let item = [];
                    data.forEach((list, i) => {
                        let newData = {};
                        newData.key = list.indexCode;
                        newData.value = list.indexCode;
                        newData.title = list.name;
                        newData.children = list.children ? ass(list.children) : [];    //如果还有子集，就再次调用自己
                        item.push(newData);
                    });
                    return item;
                }

                this.setState({
                    treeData: ass(res.data.data),
                })


            }
        });
        this.setState({
            iscvisible: true
        })
    }

    //table选择
    onSelectChange = (selectedRowKeys, b) => {
        console.log(b)
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({
            selectedRowKeys,
            indexCode: b[0].cameraIndexCode,
            cameraname: b[0].cameraName,
            deviceIds: b[0].encodeDevIndexCode,
        }, function () {
            console.log(this.state.cameraname)
        });
    };

    //树形图选择
    onSelects = (keys, event) => {
        console.log(keys)
        console.log(event)
        getIscCamerasByIndexCode([
            keys.join(',')
        ]).then(res => {
            if (res.data && res.data.message === "success") {
                for (var i in res.data.data.list) {
                    res.data.data.list[i].key = res.data.data.list[i].cameraIndexCode
                }
                this.setState({
                    iscdata: res.data.data.list
                })
            }
        });
    }

    onClose = (e) => {
        this.setState({
            iscvisible: false,
        });
    }


    lookvideo = () => {
        window.location.href = '/boardvideo';
    }

    render() {

        const prooptions = this.state.unitlist.map((province) => <Option key={province.id}  >{province.name}</Option>);



        const nodeInfoTableColumns = this.nodeInfoTableColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'age' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });



        this.camerarecordColumns = [
            {
                title: "数据类型",
                dataIndex: "dataType",
                render: (text, record, index) => {
                    if (text === 3) {
                        return (
                            <div style={{ color: '#1eb333' }}>
                                在线
                            </div>
                        )
                    }
                    if (text === 4) {
                        return (
                            <div style={{ color: '#f55238' }}>
                                离线
                            </div>
                        )
                    }
                }
            },
            {
                title: "设备编号",
                dataIndex: "deviceId",
            },
            {
                title: "上报时间",
                dataIndex: "gmtCreate",
            },
        ]


        this.recordColumns = [
            {
                title: "数据类型",
                dataIndex: "dataType",
                render: (text, record, index) => {
                    return (
                        <div>
                            {datatype[text]}
                        </div>
                    )
                }
            },
            {
                title: "设备编号",
                dataIndex: "deviceId",
            },
            {
                title: "上报时间",
                dataIndex: "gmtCreate",
            },
        ]


        if (localStorage.getItem('usertype') === "1") {
            this.cameraColumns = [
                {
                    title: "设备编号",
                    dataIndex: "deviceId",
                },
                {
                    title: "所属单位",
                    dataIndex: "unit",
                },
                {
                    title: "安装位置",
                    dataIndex: "location",
                },
                {
                    title: "设备状态",
                    dataIndex: "statusConnect",
                    filters: [
                        { text: "在线", value: 1 },
                        { text: "离线", value: 0 },
                    ],
                    onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div style={{ color: '#1eb333', cursor: 'pointer' }} onClick={() => this.lookcamera(text, record, index)} >
                                    在线
                                </div>
                            )
                        }
                        if (text === 0) {
                            return (
                                <div style={{ color: '#f55238', cursor: 'pointer' }} onClick={() => this.lookcamera(text, record, index)} >
                                    离线
                                </div>
                            )
                        }
                    }
                }, {
                    title: "激活状态",
                    dataIndex: "isActive",
                    filters: [
                        { text: "已激活", value: 1 },
                        { text: "未激活", value: 0 },
                    ],
                    onFilter: (value, record) => record.isActive == value,  //eslint-disable-line 
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div style={{ color: '#1eb333' }}>
                                    已激活
                                </div>
                            )
                        }
                        if (text === 0) {
                            return (
                                <div style={{ color: '#f55238', cursor: 'pointer' }} onClick={() => this.activeion(text, record, index)}>
                                    未激活
                                </div>
                            )
                        }
                    }
                }, {
                    title: "实时画面",
                    dataIndex: "realLive",
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#fe8616', cursor: 'pointer' }} onClick={() => this.oprealLive(text, record, index)}>
                                查看
                            </div>
                        )
                    }
                },
                {
                    title: "短信推送",
                    dataIndex: "sendMsg",
                    render: (text, record, index) => {
                        return (
                            <div >
                                <Switch
                                    checked={text}
                                    checkedChildren="开启" unCheckedChildren="关闭"
                                    onChange={() => this.switchchange(text, record, index)}
                                />
                            </div>
                        )
                    }
                },
                {
                    title: "添加时间",
                    dataIndex: "gmtCreate",
                    sorter: (a, b) => new Date(a.gmtCreate) > new Date(b.gmtCreate) ? 1 : -1,
                },
                {
                    title: "最近连接时间",
                    dataIndex: "lastConnect",
                    sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
                    render: (text, record, index) => {
                        if (text === null) {
                            return (
                                <div>
                                    无
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    {text}
                                </div>
                            )
                        }
                    }
                },
                {
                    title: "操作",
                    dataIndex: "gmtCreate",
                    render: (text, record, index) => {
                        return (
                            <div>
                                <span onClick={() => this.cameraedit(text, record, index)}>
                                    <a><img src={require('../images/edit.png')} alt="" /></a>
                                </span>
                                <span style={{ marginLeft: '10px' }} onClick={() => this.devicedelete(text, record, index)}>
                                    <a><img src={require('../images/delete.png')} alt="" /></a>
                                </span>
                            </div>
                        );
                    }
                },
            ];
        } else {
            this.cameraColumns = [
                {
                    title: "设备编号",
                    dataIndex: "deviceId",
                },
                {
                    title: "所属单位",
                    dataIndex: "unit",
                },
                {
                    title: "安装位置",
                    dataIndex: "location",
                },
                {
                    title: "设备状态",
                    dataIndex: "statusConnect",
                    filters: [
                        { text: "在线", value: 1 },
                        { text: "离线", value: 0 },
                    ],
                    onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div style={{ color: '#1eb333', cursor: 'pointer' }} onClick={() => this.lookcamera(text, record, index)} >
                                    在线
                                </div>
                            )
                        }
                        if (text === 0) {
                            return (
                                <div style={{ color: '#f55238', cursor: 'pointer' }} onClick={() => this.lookcamera(text, record, index)} >
                                    离线
                                </div>
                            )
                        }
                    }
                }, {
                    title: "实时画面",
                    dataIndex: "realLive",
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#fe8616', cursor: 'pointer' }} onClick={() => this.oprealLive(text, record, index)}>
                                查看
                            </div>
                        )
                    }
                },
                {
                    title: "添加时间",
                    dataIndex: "gmtCreate",
                    sorter: (a, b) => new Date(a.gmtCreate) > new Date(b.gmtCreate) ? 1 : -1,
                },
                {
                    title: "最近连接时间",
                    dataIndex: "lastConnect",
                    sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
                    render: (text, record, index) => {
                        if (text === null) {
                            return (
                                <div>
                                    无
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    {text}
                                </div>
                            )
                        }
                    }
                },
            ];
        }


        this.isclistcolumns = [

            {
                title: "摄像头名称",
                dataIndex: "cameraName",
                key: 'cameraName',
            }, {
                title: "摄像头类型",
                dataIndex: "cameraTypeName",
                key: 'cameraTypeName',
            }, {
                title: "IndexCode",
                dataIndex: "cameraIndexCode",
                key: 'cameraIndexCode',
            }, {
                title: "创建时间",
                dataIndex: "createTime",
                key: 'createTime',
                render: (text, record, index) => {
                    return (
                        <div>
                            {moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                }
            },
        ];



        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            type: "radio",
        };


        const components = {
            // body: {
            //     row: EditableFormRow,
            //     cell: EditableCell,
            // },
        };

        const listion = <img src={require('../images/close.png')} alt="" />
        return (
            <Layout id="device" >
                <Layout>
                    <Content style={{ margin: "20px 0px" }} >
                        <div>
                            <Tabs defaultActiveKey="2">
                                <TabPane tab="摄像头" key="2">
                                    <div className="contentmain">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <span>所属单位：</span>
                                                <Cascader
                                                    fieldNames={{ label: 'name', value: 'id' }}
                                                    options={this.state.deviceList}
                                                    onChange={this.addresschange}
                                                    value={this.state.addresslist}
                                                    changeOnSelect
                                                    style={{ width: "300px", marginRight: '10px' }}
                                                    placeholder="请选择单位" />
                                                <Search placeholder="请输入设备编号"
                                                    onSearch={this.onsearch}
                                                    onChange={this.searchname}
                                                    value={this.state.searchname}
                                                    enterButton style={{ marginBottom: '20px', width: '300px' }}
                                                />
                                                <Button type="primary" onClick={this.export} style={{ display: this.state.typedis, marginLeft: '10px' }} >
                                                    数据导出
                                            </Button>
                                            </div>
                                            <div>
                                                {/* <Button type="primary" onClick={this.lookvideo}  >
                                                    视频看板
                                            </Button> */}
                                                <Button type="primary" onClick={this.addcamera} style={{ display: this.state.typedis, marginLeft: '15px' }} >
                                                    添加设备
                                            </Button>
                                            </div>
                                        </div>
                                        <Table
                                            dataSource={this.state.cameralist}
                                            columns={this.cameraColumns}
                                            pagination={this.state.camerapage}
                                            components={components}
                                            onChange={this.cameralistchange}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tab="传感器" key="1">
                                    <div className="contentmain">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <span>所属单位：</span>
                                                <Cascader
                                                    fieldNames={{ label: 'name', value: 'id' }}
                                                    options={this.state.deviceList}
                                                    onChange={this.addresschange}
                                                    value={this.state.addresslist}
                                                    changeOnSelect
                                                    style={{ width: "300px", marginRight: '10px' }}
                                                    placeholder="请选择单位" />
                                                <Search placeholder="请输入设备编号"
                                                    onSearch={this.onsearchs}
                                                    onChange={this.searchnames}
                                                    value={this.state.searchnames}
                                                    enterButton style={{ marginBottom: '20px', width: '300px' }}
                                                />
                                                <Button type="primary" onClick={this.exports} style={{ display: this.state.typedis, marginLeft: '10px' }} >
                                                    数据导出
                                            </Button>
                                            </div>
                                            <Button type="primary" onClick={this.adddevice} style={{ display: this.state.typedis }} >
                                                添加设备
                                            </Button>
                                        </div>
                                        <Table
                                            dataSource={this.state.sensorlist}
                                            columns={nodeInfoTableColumns}
                                            pagination={this.state.page}
                                            components={components}
                                            onChange={this.sensorlistchange}
                                        />
                                    </div>
                                </TabPane>



                            </Tabs>

                        </div>
                    </Content>
                    <Modal
                        title="删除设备"
                        visible={this.state.devicedeletevisible}
                        onOk={this.deletedevice}
                        width="300px"
                        okText="删除"
                        centered
                        onCancel={this.handleCancel}
                        closeIcon={listion}
                    >
                        您确定要删除该设备吗？
                    </Modal>
                    <Modal
                        title="传感器上下线记录"
                        visible={this.state.recordvisible}
                        width="550px"
                        centered
                        footer={null}
                        closeIcon={listion}
                        onCancel={this.handleCancel}
                    // closable={false}
                    >
                        <div className="modeltable">
                            <Table
                                dataSource={this.state.recordlist}
                                columns={this.recordColumns}
                                pagination={false}
                                bordered
                            />
                        </div>
                        <div className="pageone" style={{ textAlign: 'right', marginTop: '10px' }}>
                            <Pagination
                                onShowSizeChange={this.onShowSizeChange}
                                defaultCurrent={1}
                                onChange={this.sensorchange}
                                total={this.state.sensortotal}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </Modal>
                    <Modal
                        title="摄像头上下线记录"
                        visible={this.state.camerarecord}
                        width="550px"
                        centered
                        footer={null}
                        onCancel={this.handleCancel}
                        closeIcon={listion}
                    >
                        <div className="modeltable">
                            <Table
                                dataSource={this.state.recordlist}
                                columns={this.camerarecordColumns}
                                pagination={false}
                                bordered
                            />
                        </div>
                        <div className="pageone" style={{ textAlign: 'right', marginTop: '10px' }}>
                            <Pagination
                                onShowSizeChange={this.onShowSizeChange}
                                defaultCurrent={1}
                                onChange={this.pagechange}
                                total={this.state.cameratotal}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </Modal>
                    <Modal
                        title="实时画面"
                        visible={this.state.videovisible}
                        width="100%"
                        height="300"
                        centered
                        onCancel={this.handleCancels}
                        closeIcon={listion}
                        footer={null}
                        destroyOnClose
                    >
                        {/* <video id="myVideo" controls style='width: 100%;height: auto'>
                            <source id="source" src={this.state.videourl} type="application/x-mpegURL"></source>
                        </video> */}
                        {/* <video width="100%" controls="controls" type="application/x-mpegURL" autoPlay="autoplay" loop="loop"  >
                            <source src={this.state.videourl} type="application/x-mpegURL" />
                        </video> */}
                        <VideoPlayer
                            // style={{ width: '100%', height: '200px' }}
                            src={this.state.videourl}
                        ></VideoPlayer>
                    </Modal>
                    <Modal
                        title="设备激活"
                        visible={this.state.activevisible}
                        width="330px"
                        centered
                        onOk={this.activeok}
                        onCancel={this.handleCancel}
                        closeIcon={listion}
                    >
                        <span>验证码：</span>
                        <Input
                            style={{ width: '200px', marginLeft: '10px' }}
                            autoComplete="off"
                            placeholder="请输入验证码"
                            value={this.state.activenum}
                            onChange={this.activenum}
                        />
                    </Modal>
                    <Modal
                        title="摄像头"
                        visible={this.state.cameravisible}
                        onOk={this.cameraOk}
                        width="400px"
                        okText="确认"
                        centered
                        onCancel={this.handleCancel}
                        closeIcon={listion}
                    >

                        <span>设备类型：</span>
                        {/* <Select placeholder="请选择设备类型" style={{ width: "100%", marginBottom: "10px", marginTop: '10px' }} onChange={this.devicetype} value={this.state.devicetype}>
                            <Option value={2}>摄像头</Option>
                            <Option value={1}>烟雾传感器</Option>
                        </Select> */}
                        <Input
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            value="摄像头"
                        />
                        <span style={{ display: 'block' }}>设备编号：</span>
                        <Search
                            placeholder="请选择摄像头"
                            enterButton="摄像头列表"
                            size="middle"
                            onSearch={this.iscquery}
                            value={this.state.cameraname}
                            style={{ width: '100%', marginBottom: "5px", marginTop: '5px', fontSize: '14px' }}
                        />
                        <span>所属单位：</span>
                        <Select
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            placeholder="请选择所属单位"
                            onChange={this.unitnames}
                            value={this.state.unitnames}
                        >
                            {prooptions}
                        </Select>
                        <span>设备位置：</span>
                        <Input placeholder="请输入设备位置"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            onChange={this.locations}
                            value={this.state.locations}
                        />
                    </Modal>
                    <Modal
                        title="传感器"
                        visible={this.state.devicevisible}
                        onOk={this.addOk}

                        width="400px"
                        okText="确认"
                        centered
                        onCancel={this.handleCancel}
                        closeIcon={listion}
                    >

                        <span>设备类型：</span>
                        <Select placeholder="请选择设备类型" style={{ width: "100%", marginBottom: "10px", marginTop: '10px' }} onChange={this.devicetype} value={this.state.devicetype}>
                            <Option value={1}>连防05烟感</Option>
                            <Option value={3}>电信烟感</Option>
                            <Option value={4}>连防09烟感</Option>
                        </Select>
                        {/* <Input
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            value="烟雾传感器"
                        /> */}
                        <span style={{ display: 'block' }}>设备编号：</span>
                        <Input placeholder="请输入设备编号"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            onChange={this.deviceId}
                            value={this.state.deviceId}
                        />
                        <div>
                            <span>设备IMEI号：</span>
                            <Input placeholder="请输入设备IMEI号"
                                style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                                autoComplete="off"
                                onChange={this.imei}
                                value={this.state.imei}
                            />
                        </div>
                        <span>所属单位：</span>
                        <Select
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            placeholder="请选择所属单位"
                            onChange={this.unitname}
                            value={this.state.unitname}
                        >
                            {prooptions}
                        </Select>
                        <span>设备位置：</span>
                        <Input placeholder="请输入设备位置"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            onChange={this.location}
                            value={this.state.location}
                        />
                    </Modal>
                    <Drawer
                        title="摄像头列表"
                        width={1100}
                        onClose={this.onClose}
                        visible={this.state.iscvisible}
                        className="chouti"
                        bodyStyle={{ paddingBottom: 80 }}
                    >
                        <div className="drawermain">
                            <div className="treelist">
                                <DirectoryTree
                                    fieldNames={{ title: 'name', key: 'indexCode' }}
                                    multiple
                                    defaultExpandAll
                                    onSelect={this.onSelects}
                                    // onExpand={onExpand}
                                    treeData={this.state.treeData}
                                />
                            </div>
                            <div style={{ width: '650px' }}>
                                < Table
                                    // components={components} 
                                    dataSource={this.state.iscdata}
                                    rowSelection={rowSelection}
                                    columns={this.isclistcolumns}
                                    bordered
                                />
                                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                    <Button type="primary" onClick={this.submitcamera}>确定</Button>
                                </div>
                            </div>
                        </div>

                    </Drawer>
                </Layout>
            </Layout >
        );
    }
}

export default App;
