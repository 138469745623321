import React, { Component } from 'react';
import { Button, Menu, Input, Layout, Table, Modal, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getpersonal, getToken, insertAlarmFeedback } from '../axios';
import './mobiles.css';

const { TextArea } = Input;
const { Header, Sider, Content } = Layout;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class journal extends Component {
  state = {
    recordlist: [{
      "num1": "27.70",
      "num2": "7.499",
      "num3": "0.699",
      "num4": "0.36",
      "num5": "752"
    }]
  }
  componentWillMount = () => {
    document.title = "舟山泳馆水质信息公示";
  }

  componentDidMount = () => {

  }


  lookimg = (text, record, index) => {
    this.setState({
      imgvisible: true,
      img: text
    })
  }

  //关闭
  handleCancel = () => {
    this.setState({
      imgvisible: false,
    })
  }

  //登录
  btnquery = () => {
    setTimeout(function () {
      window.location.href = "/submitsuccess";
    }, 1000);
  }



  render() {
    this.camerarecordColumns = [
      {
        title: "温度",
        dataIndex: "num1",
        align: "center"
      },
      {
        title: "PH",
        dataIndex: "num2",
        align: "center",
      },
      {
        title: "余氯",
        dataIndex: "num3",
        align: "center",
      },
      {
        title: "浊度",
        dataIndex: "num4",
        align: "center",
      }, {
        title: "ORP",
        dataIndex: "num5",
        align: "center",
      }
    ]




    return (
      <div id="mobiles" >
        <div className="title">
          舟山泳馆水质信息公示
        </div>
        <div className="conthead">
          <div className="container">
            <div className="header">
              <div className="line">

              </div>
              <div className="headertitle">
                泳馆信息
              </div>
            </div>
            <div className="contline">
              单位名称：浙江海洋大学-游泳馆
            </div>
            <div className="contline">
              地址：舟山市定海区海院路18号浙江海洋学院
            </div>
            <div className="contline">
              卫生许可项目：游泳
            </div>
            <div className="contline">
              卫生量化等级：B级
            </div>
            <div className="contline">
              卫生监测信息： <soan style={{ color: '#6ECA89' }}>合格</soan>
            </div>
            <div className="contline"  >
              酒店投诉电话：0571-87556188
            </div>
            <div className="dashedline"></div>
            <div className="header">
              <div className="line">

              </div>
              <div className="headertitle  headerflex">
                <div> 水质信息</div>
                <div className="headeright">更新时间: <span style={{ color: '#46B750' }}>2021-11-29 16:04</span></div>
              </div>
            </div>
            <div className="contline">
              <Table
                dataSource={this.state.recordlist}
                columns={this.camerarecordColumns}
                pagination={false}
                bordered
              />
            </div>
            <div className="contline">
              水质监测结果： <soan style={{ color: '#6ECA89' }}>合格</soan>
            </div>
            <div className="header">
              <div className="line">

              </div>
              <div className="headertitle">
                健康信息
              </div>
            </div>
            <div className="contline">
              游泳禁忌症不得入人工游泳池游泳的事项已被告知并知晓。
            </div>
            <div className="contline">
              本人（或被监护人）承诺在开展游泳活动期间无肝炎、心脏病、皮肤癣疹（包括脚癣）、重症沙眼、急性结膜炎、中耳炎、肠道传染病、精神病等游泳禁忌症，未酗酒。
            </div>
            <div className="contlines">
              <label style={{ display: 'flex', alignItems: 'center' }}><input name="Fruit" type="radio" value="" /> &nbsp;本人已了解并同意 </label>
            </div>
            <div className="contline">
              姓名
            </div>
            <div className="contline">
              <input type="text" value="" className="textinput" />
            </div>
            <div className="contline">
              电话号码
            </div>
            <div className="contline">
              <input type="text" value="" className="textinput" />
            </div>
            <div className="submitbtn"  onClick={this.btnquery}>
              提交
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default journal = (journal);

