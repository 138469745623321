import React, { Component } from "react";
import { Button, Input, message } from "antd";
import { v4 as uuidv4 } from 'uuid';
import "./close.css";
import moment from 'moment';



class SignIn extends Component {
  state = {

  };

  componentwillMount = () => {
    localStorage.clear();

  }
  componentDidMount = () => {

  };



  render() {
    return (
      <div id="signbody">
        <img src="https://zjiva.terabits.cn/var/file/closeback.png" alt="" className="dashimg" />
      </div>

    );
  }
}

export default SignIn;