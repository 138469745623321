import React, { Component } from 'react';
import { Button, Menu, Input, Layout, Table, Modal, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getpersonal, getToken, insertAlarmFeedback } from '../axios';
import './mobile.css';

const { TextArea } = Input;
const { Header, Sider, Content } = Layout;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class journal extends Component {
  state = {
    recordlist: [{
      "area": "走道",
      "img": "https://i.ys7.com/streamer/alarm/url/get?fileId=20211129094833-F89215329-2-10041-2-1&deviceSerialNo=F89215329&cn=2&isEncrypted=0&isCloudStored=0&ct=30&lc=7&bn=30_hikalarm-txsh-1256683041&isDevVideo=0",
      "time": "2021-11-29 09:48:32"
    }, {
      "area": "走道",
      "img": "https://i.ys7.com/streamer/alarm/url/get?fileId=20211129012257-F89215329-2-10041-2-1&deviceSerialNo=F89215329&cn=2&isEncrypted=0&isCloudStored=0&ct=30&lc=7&bn=30_hikalarm-txsh-1256683041&isDevVideo=0",
      "time": "2021-11-29 01:22:56"
    }, {
      "area": "走道",
      "img": "https://i.ys7.com/streamer/alarm/url/get?fileId=20211128221121-F89215329-2-10041-2-1&deviceSerialNo=F89215329&cn=2&isEncrypted=0&isCloudStored=0&ct=30&lc=7&bn=30_hikalarm-txsh-1256683041&isDevVideo=0",
      "time": "2021-11-28 22:11:20"
    }, {
      "area": "门诊楼廊道",
      "img": "https://i.ys7.com/streamer/alarm/url/get?fileId=20211128194044-F89215303-2-10041-2-1&deviceSerialNo=F89215303&cn=2&isEncrypted=0&isCloudStored=0&ct=30&lc=7&bn=30_hikalarm-txsh-1256683041&isDevVideo=0",
      "time": "2021-11-28 19:40:43"
    }, {
      "area": "门诊楼廊道",
      "img": "https://i.ys7.com/streamer/alarm/url/get?fileId=20211128193644-F89215303-2-10041-2-1&deviceSerialNo=F89215303&cn=2&isEncrypted=0&isCloudStored=0&ct=30&lc=7&bn=30_hikalarm-txsh-1256683041&isDevVideo=0",
      "time": "2021-11-28 19:36:44"
    }]
  }
  componentWillMount = () => {
    document.title = "上传照片";
  }

  componentDidMount = () => {

  }


  lookimg = (text, record, index) => {
    this.setState({
      imgvisible: true,
      img: text
    })
  }

  //关闭
  handleCancel = () => {
    this.setState({
      imgvisible: false,
    })
  }


  render() {
    this.camerarecordColumns = [
      {
        title: "区域",
        dataIndex: "area",
        align: "center"
      },
      {
        title: "画面",
        dataIndex: "img",
        align: "center",
        render: (text, record, index) => {
          return (
            <div style={{ color: '#1eb333', cursor: 'pointer' }} onClick={() => this.lookimg(text, record, index)}>
              查看
            </div>
          )
        }
      },
      {
        title: "时间",
        dataIndex: "time",
        align: "center",
      },
    ]




    return (
      <div id="mobile" >
        <div className="title">
          衢州市违禁吸烟监控智能分析平台
        </div>
        <div className="conthead">
          <div className="container">
            <div className="header">
              <div className="line">

              </div>
              <div className="headertitle">
                单位详情
              </div>
            </div>
            <div className="contline">
              单位名称：浙江大学医学院附属儿童医院(湖滨院区)
            </div>
            <div className="contline">
              控烟负责人：孙幼君
            </div>
            <div className="contline">
              负责人电话：13588196769
            </div>
            <div className="header">
              <div className="line">

              </div>
              <div className="headertitle">
                近7日劝阻情况
              </div>
            </div>
            <div className="contline">
              违禁吸烟告警总数 <b>31</b> 次    劝阻总数 <b>22</b> 次
            </div>
            <div className="header">
              <div className="line">

              </div>
              <div className="headertitle">
                近期告警详情
              </div>
            </div>
            <div className="contline">
              <Table
                dataSource={this.state.recordlist}
                columns={this.camerarecordColumns}
                pagination={false}
                bordered
              />
            </div>
            <Modal
              title="抓拍画面"
              visible={this.state.imgvisible}
              centered
              onCancel={this.handleCancel}
              footer={null}
              width="90%"
              className="imgmodel"
              header={null}
            >
              <img src={this.state.img} alt="" style={{ width: '100%' }} />
            </Modal>
          </div>
        </div>
      </div >
    )
  }
}

export default journal = (journal);

