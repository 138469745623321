import React, { Component } from "react";
import { Button, Input, message, Tabs } from "antd";
import { url } from '../axios';
import http from 'axios';
import "./SignIn.css";


const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

class SignIn extends Component {
  state = {
    userID: "",
    password: ""
  };

  componentwillMount = () => {
    localStorage.clear();


  }
  componentDidMount = () => {
    let loginurl = window.location.href;
    loginurl = loginurl.split('?==');
    console.log(loginurl)
    if (loginurl.length > 1) {
      let loginurls = loginurl[1].split('&')
      http.defaults.headers.get['Content-Type'] = "application/x-www-form-urlencoded"
      http.get(url + '/login?username=' + loginurls[0] + '&password=' + loginurl[2] + '&platId=2&type=user&grant_type=password', {
        auth: {
          username: "webApp",
          password: 'webApp',
        }
      }).then(res => {
        if (res.data.status === 1003) {
          message.error("用户名不存在！");
        }
        if (res.data.status === 1004) {
          message.error("密码错误");
        }
        if (res.data.status === -1) {
          message.error("账号已禁用");
        }
        if (res.data.status === 1) {
          localStorage.setItem('token', res.data.data.access_token);
          localStorage.setItem('usertype', res.data.data.type);
          localStorage.setItem('realname', res.data.data.realname);
          localStorage.setItem('menuid', 3)
          localStorage.setItem("menulist", JSON.stringify(res.data.data.menu));
          localStorage.setItem("unitTree", res.data.data.unitTree.length === 0 ? null : JSON.stringify(res.data.data.unitTree[0].children));
          localStorage.setItem("AreaTree", res.data.data.AreaTree.length === 0 ? null : JSON.stringify(res.data.data.AreaTree[0].children));
          window.location.href = '/app/alarm';
        }
      })
    }
  };


  logindown = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit()
    }
  }

  handleSubmit = (e) => {
    if (this.state.userID === "") {
      message.error("请输入账号");
    }
    else if (this.state.password === "") {
      message.error("请输入密码");
    } else {
      this.setState({
        loading: true,
      })
      http.defaults.headers.get['Content-Type'] = "application/x-www-form-urlencoded"
      http.get(url + '/login?username=' + this.state.userID + '&password=' + this.state.password + '&platId=2&type=user&grant_type=password', {
        auth: {
          username: "webApp",
          password: 'webApp',
        }
      }).then(res => {
        if (res.data.status === 1003) {
          message.error("用户名不存在！");
          this.setState({
            loading: false,
          })
        }
        if (res.data.status === 1004) {
          message.error("密码错误");
          this.setState({
            loading: false,
          })
        }
        if (res.data.status === -1) {
          message.error("账号已禁用");
        }
        if (res.data.status === 1) {
          message.success('登录成功');
          this.setState({
            loading: false,
          })
          localStorage.setItem('token', res.data.data.access_token);
          localStorage.setItem('usertype', res.data.data.type);
          localStorage.setItem('realname', res.data.data.realname);
          localStorage.setItem('menuid', 3)
          localStorage.setItem("menulist", JSON.stringify(res.data.data.menu));
          localStorage.setItem("unitTree", res.data.data.unitTree.length === 0 ? null : JSON.stringify(res.data.data.unitTree[0].children));
          localStorage.setItem("AreaTree", res.data.data.AreaTree.length === 0 ? null : JSON.stringify(res.data.data.AreaTree[0].children));
          setTimeout(() => {
            window.location.href = '/app/alarm';
          }, 1000)
        }
      })
    }
  }

  render() {
    return (
      <div id="signbody">
        <div style={{ display: "flex", flexDirection: 'column' }}>
          <div className="SignIn-body">
            <div className="cover">
              <div className="logo">
                <img src='https://mainimg.terabits.cn/smokelogo.png' alt="" style={{ width: "70px", marginRight: '20px' }} />
                衢州市违禁吸烟监控智能分析平台
              </div>
              <div className="loginmain">
                <div className="loginl">

                </div>
                <div className="loginr">
                  <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="账号登录" key="1">
                      <div>
                        <Input
                          size="large"
                          className="SignIn-Input"
                          placeholder="请输入用户名"
                          prefix={
                            <span style={{ borderRight: '1px solid #d9d9d9', paddingRight: '10px' }}>
                              <img src={require('./user.png')} alt="" style={{ width: "25px" }} />
                            </span>
                          }
                          onChange={e => this.setState({ userID: e.target.value })}
                          value={this.state.userID}
                          onKeyDown={this.logindown}
                        />
                      </div>
                      <div>
                        <Input
                          size="large"
                          className="SignIn-Inputs"
                          placeholder="请输入密码"
                          prefix={
                            <span style={{ borderRight: '1px solid #d9d9d9', paddingRight: '10px' }}>
                              <img src={require('./pass.png')} alt="" style={{ width: "25px" }} />
                            </span>
                          }
                          type="password"
                          onChange={e => this.setState({ password: e.target.value })}
                          value={this.state.password}
                          onKeyDown={this.logindown}
                        />
                      </div>
                      <div>
                        <Button
                          className="SignIn-requestbutton"
                          onClick={() => {
                            this.handleSubmit();
                          }}
                          style={{ height: '40px', width: '100%', fontSize: '18px', background: '#fc710f', color: 'white', border: 'none' }}
                        >
                          <span>登录</span>
                        </Button>
                      </div>
                    </TabPane>
                    {/* <TabPane tab="浙政钉登录" key="2">
                      <iframe src="https://login-pro.ding.zj.gov.cn/oauth2/auth.htm?response_type=code&client_id=qzsmoke_dingoa&redirect_uri=http://qzsmoke.terabits.cn/zs/smoke/api/v1/security/codeScanningLogin&scope=get_user_info&authType=QRCODE&embedMode=true"
                        width="315"
                        height="300"
                        style={{ border: 'none', marginLeft: '-35px', overflow: 'hidden', marginTop: '-100px' }}
                        scrolling="no"
                        onLoad={() => {
                          window.addEventListener('message', function (event) {
                            var arr = 'http://qzsmoke.terabits.cn/zs/smoke/api/v1/security/codeScanningLogin?code=' + JSON.stringify(event.data.code).replace(/\"/g, "")
                            window.location.href = arr
                          });

                        }}
                      >

                      </iframe>

                    </TabPane> */}
                  </Tabs>
                </div>
              </div>
              <div className="bombtn">
                平台服务商：&nbsp;&nbsp;<a href="http://www.terabits.cn/" target="_blank" rel="noopener noreferrer" style={{ color: '#666666' }}>杭州钛比科技有限公司</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：&nbsp;&nbsp;0571-87755736
              </div>
              <div className="bombtns">
                浙ICP备16003817号-1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;网站标识码：3610782
              </div>
              <div className="bombtns">
                <img src={require('./bot.png')} alt="" style={{ width: '20px', marginRight: '10px' }} />
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602007808" target="_blank" rel="noopener noreferrer" style={{ color: '#666666' }}>浙公网安备33010602009975号</a>
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default SignIn;