import React, { Component } from 'react'
import './boardvideo.css'
import { Row, Col, Tree, Tabs, Input, Button, Spin, Layout, } from 'antd';
import { getIscAreaList, getIscCamerasByIndexCode } from '../axios'
import PlayBack from './components/playback'
import Headers from '../headers';
import { UndoOutlined, VideoCameraOutlined, SearchOutlined } from '@ant-design/icons'
const { DirectoryTree } = Tree;
const { TabPane } = Tabs
const { Content, Sider, Header } = Layout;
export default class boardvideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      type: 'board',
      videoStatus: '0',
      expandFlag: ['af571a16-b896-4731-9103-57863c1aa10f'],
      select_treenodes: ['af571a16-b896-4731-9103-57863c1aa10f'],
      loading: false
    };
  }

  componentDidMount() {
    this.init()
  }
  // 初始化请求设备数据
  init = () => {
    this.setState({
      loading: true
    })
    getIscAreaList([

    ]).then(res => {
      if (res.data && res.data.message === "success") {
        console.log(res.data.data)
        const ass = (data) => {
          let item = [];
          data.forEach((list, i) => {
            let newData = {};
            newData.key = list.indexCode;
            newData.value = list.indexCode;
            newData.title = list.name;
            newData.children = list.children ? ass(list.children) : list(list.indexCode);    //如果还有子集，就再次调用自己
            item.push(newData);
          });
          return item;
        }
        this.setState({
          treeData: ass(res.data.data),
          loading: false,
        })
      }
    });
  }
  onSelect = (val, item) => {
    console.log(val, item)
    // this.Videopreview.boardPreivew('d1f708b1b16a4fd18ed3a0592c1ad019')
    // if (item.node.children.length > 0) {
    //   return
    // }
    if (this.state.videoStatus === '0') {
      this.PlayBack.boardPreivew(val[0])
    } else {
      this.PlayBack.startPlayBack(val[0])
    }

    if (item.node.children.length > 0) {
      console.log(this.state.treeData)
      return
    } else {
      getIscCamerasByIndexCode([
        val[0]
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          var arr = []
          for (var i in res.data.data.list) {
            arr.push({
              "title": res.data.data.list[i].cameraName,
              "value": res.data.data.list[i].cameraIndexCode,
              "key": res.data.data.list[i].cameraIndexCode,
              "children": []
            })
          }
          const newarr = (data) => {
            data.forEach((list, i) => {
              if (list.value === val[0]) {
                list.children = arr
              } else {
                newarr(list.children)
              }
            });
          }
          // this.setState({
          //   treeData: newarr(this.state.treeData)
          // })
          console.log(this.state.treeData)
          console.log(newarr(this.state.treeData))
        }
      });

    }
  }
  onRef = ref => {
    this.PlayBack = ref;
  }

  
  // 切换标签
  changeTab = (val) => {
    this.PlayBack.destroy()
    this.PlayBack.initPlugin(val)
    this.setState({
      videoStatus: val
    })
  }

  // 展开/收起节点时触发（把指定张开的父节点重新赋值；如果加了 expandedKeys，就必须用这一步，不然不能展开节点以及收起节点）
  onExpand = (expandedKeys, b) => {
    this.setState({
      expandFlag: expandedKeys
    })
    if (b.expanded === true) {
      getIscCamerasByIndexCode([
        expandedKeys[expandedKeys.length - 1]
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          var arr = []
          for (var i in res.data.data.list) {
            arr.push({
              "title": res.data.data.list[i].cameraName,
              "value": res.data.data.list[i].cameraIndexCode,
              "key": res.data.data.list[i].cameraIndexCode,
              "children": []
            })
          }
          const newarr = (data) => {
            data.forEach((list, i) => {
              if (list.value === expandedKeys[expandedKeys.length - 1]) {
                list.children = arr
              } else {
                newarr(list.children)
              }
            });
          }
        }
      });
    }
  }


  download = () => {
    window.open("https://mainimg.terabits.cn/VideoWebPlugin.exe", '_self')
  }

  back = () => {
    window.location.href = '/app/device';
  }

  //当搜索框输入时触发
  SearchChange = (e) => {
    // console.log(e.target.value) //输入的值打印
    let data = JSON.parse(JSON.stringify(this.state.treeData)); //节点数据；
    // console.log(data)
    let arr = []
    data.map(el => {
      if (el.name.indexOf(e.target.value) != -1) {

        arr.push(el.indexCode)
      }
      if (el.children && el.children.length > 0) {
        el.children.map(item => {
          if (item.name.indexOf(e.target.value) != -1) {
            arr.push(item.indexCode)
          }
          if (item.children && item.children.length > 0) {
            item.children.map(one => {
              if (one.name.indexOf(e.target.value) != -1) {
                arr.push(one.indexCode)
              }
            })
          }
        })
      }
      // console.log()
      this.setState({
        select_treenodes: arr,
        expandFlag: arr
      })
      console.log(arr)
    })
    //遍历节点数据
    // for (let i = 0; i < data[0].children.length; i++) {
    //   if (data[0].children[i].name == e.target.value) {  //如果输入的值等于节点的title，把节点张开，标记节点；
    //     console.log(data[0].children[i].key) //打印key，如果key是number类型则要转化成String类型；
    //     this.setState({
    //       select_treenodes: [data[0].children[i].indexCode], //设置选中的树节点
    //       expandFlag: [String(data[0].indexCode)],    //展开指定父节点
    //     })
    //   }
    // }
    // console.log(this.state.tree_data)
  }
  render() {
    const { expandFlag, select_treenodes, treeData } = this.state

    const operations = <div style={{ background: "white" }}>
      <Button type="primary" onClick={() => this.back()} style={{ marginRight: '10px' }}>返回</Button>
      <Button type="primary" onClick={() => this.download()} style={{ marginLeft: '15px', marginRight: '10px' }}>插件下载</Button>
      （若无插件请点击此按钮进行插件下载并安装）</div>;
    return (
      <div id="boardvideo">
        <Header >
          <Headers />
        </Header>
        <Row style={{ marginTop: '20px' }}>
          <Col span={6}>
            {/* <Input placeholder="输入查询设备" prefix={<SearchOutlined />} onChange={this.SearchChange} /> */}
            <Spin spinning={this.state.loading} tip="设备列表正在加载中...">
              <DirectoryTree
                // multiple
                // expandedKeys={this.state.expandedKeys}
                expandedKeys={expandFlag}
                onSelect={this.onSelect}
                onExpand={this.onExpand}
                // onExpand={onExpand}
                height={'100%'}
                selectedKeys={select_treenodes}
                treeData={treeData}
                fieldNames={{ title: 'name', key: 'indexCode', children: 'children' }}
              />
            </Spin>
          </Col>
          <Col span={18}>
            {/* <Videopreview type={this.state.type} onRef={this.onRef} /> */}
            <Tabs defaultActiveKey="0" onChange={this.changeTab} tabBarExtraContent={operations}>
              <TabPane
                tab={
                  <span>
                    <VideoCameraOutlined />
                    实时预览
                  </span>
                }
                key="0"
              >
                {/* <Videopreview type={this.state.type} onRef={this.onRef} /> */}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <UndoOutlined />
                    录像回放
                  </span>
                }
                key="1"
              >
                {/* <Videopreview type={this.state.type} onRef={this.onRef} videoStatus={this.state.videoStatus} /> */}

              </TabPane>
            </Tabs>
            <PlayBack onRef={this.onRef} />
          </Col>
        </Row>
      </div>
    )
  }
}
